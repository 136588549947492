import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getOneConfig = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/config/' + id, {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data);
}

export const updateConfig = async (data, id) => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined,
                'Content-Type': 'application/json',
            }
        }).post(`admin/config/${id}`, data);
    });
}

export const getListEmailSend = async () => {
    const result = await axios(config.baseUrl + 'api/v1/admin/email/', {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data);
}

export const getOneEmailSend = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/email/' + id, {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data);
}



