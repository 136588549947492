import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOneEmailSend } from '../../store/index.service';
import { HeaderList } from '../../components/HeaderList/HeaderList';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { sanitizeHtml } from "../../helpers";


export const OneEmailSend = () => {
    let { id } = useParams();
    const [oneEmailSend, setOneEmailSend] = useState({loading: true, data: []});

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getOneEmailSend(id).then(res => {
            setOneEmailSend({
                data: res.data ?? []
            });
        });
    }

    return (
        <div className="list">
            <HeaderList title="Email" icon={faNewspaper}></HeaderList>
            <div className="info-send">
                <div className="info-send-title">
                    <h2>{oneEmailSend.data.subjet}</h2>
                </div>
                <div className="info-send-content">
                    <p>{sanitizeHtml(oneEmailSend.data.message)}</p>
                </div>
            </div>
            <div className="grid-container">
                {oneEmailSend.data.dataProd && oneEmailSend.data.dataProd.map((prod, key) => (
                    <div className="grid-container-all">
                        <div className="card">
                            <img
                                src={prod.data["fr"].background.data.path}
                                alt={prod.data["fr"].title.data}
                            />
                            <div>
                                <h3>{prod.data["fr"].title.data}</h3>
                            </div>
                            <div className="text-input">
                                <p>{sanitizeHtml(prod.comment)}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}