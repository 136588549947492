import { useState, useMemo, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import { addMedia } from '../../../store/index.service';
import { blobToBase64 } from "../../../helpers";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: 15,
    gap: 15
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: "relative",
    cursor: "pointer"
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


export const AddMedia = ({ handleClosePopup, refreshData = () => {} }) => {

    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: true,
        accept: { 'image/*': [] },
        onDrop: acceptedFiles => {
            setFiles([
                ...files,
                ...acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            ]);
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name} className="thumb">

            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>

            <div className="overlay" onClick={() => removeFile(file)}>
                <FontAwesomeIcon icon={faTrash} className="trash-icon" size="2x" />
            </div>

        </div>
    ));

    const handleAddMedia = () => {

        setLoading(true);

        Promise.all(files.map(el => blobToBase64(el)))
            .then(res => {

                addMedia(res)
                    .then(() => {
                        setLoading(false);
                        refreshData();
                        handleClosePopup();
                    })
            });
    }

    const removeFile = (file) => {
        setFiles(files.filter(el => el !== file))
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <div className='popupCustom popupAddMedia'>

            <div {...getRootProps({ className: 'dropzone', style })}>

                <input {...getInputProps()} />

                <FontAwesomeIcon icon={faUpload} size="2x" />
                <p>Drag & drop des fichiers ici, ou cliquez pour ouvrir l'explorateur de fichiers</p>

            </div>

            <aside style={thumbsContainer}>
                {thumbs}
            </aside>

            <div className="btn-container">

                <Button 
                    variant="text"
                    color="grey"
                    size="small"
                    className="cancel"
                    onClick={handleClosePopup}
                >
                    Annuler
                </Button>

                <Button 
                    variant="contained"
                    disableElevation
                    onClick={handleAddMedia}
                >
                    Valider
                    <ClipLoader
                        color={"#fff"}
                        loading={loading}
                        size={20}
                        cssOverride={{ marginLeft: 5 }}
                    />
                </Button>

            </div>

        </div>
    )
}