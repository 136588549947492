import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import { FormInputText } from '../../Form/FormInputText';
import { FormButtonSubmit } from "../../Form/FormButtonSubmit";
import { updateUser, getOneUser } from '../../../store/index.service';
import { FormAutocomplete } from "../../Form/FormAutocomplete";
import { NotificationManager } from "react-notifications";

export const UpdateUser = ({ handleClosePopup, refreshData, id }) => {

    const { handleSubmit, control, setValue } = useForm();

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const typeRole = [
        { label: "Admin", id: "ROLE_ADMIN" },
        { label: "Utilisateur", id: "ROLE_USER" },
    ];

    useEffect(() => {
        getOneUser(id).then(res => {

            setValue("email", res.email);
            setValue("firstName", res.firstName);
            setValue("lastName", res.lastName);
            setValue("role", typeRole.find(el => el.value === res.role[0]));

        });
    }, []);

    const onSubmit = data => {

        setLoading(true);

        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data)) {
            if(key === "role" && value)
                formData.append(key, value.id);
            else
                formData.append(key, value);
        }

        updateUser(formData, id)
            .then(() => {
                refreshData();
                handleClosePopup();
                NotificationManager.success("", "Utilisateur modifié");
            })
            .catch(err => {
                console.error(err);
                setLoading(false);

                if(err.response.status === 409)
                    setErrorMsg(err.response.data.result);
                else
                    setErrorMsg("Une erreur est survenue");
            })
    }

    return (
        <div className='popupCreateHorseQuick'>

            <h2>Editer un utilisateur</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                    
                    <div className="row-form">
                        <FormInputText
                            name="email"
                            label="Email"
                            control={control}
                            rules={{ required: true }}
                        />
                    </div>

                    <div className="row-form">
                        <FormInputText
                            name="firstName"
                            label="Prénom"
                            control={control}
                            rules={{ required: true }}
                        />
                    </div>

                    <div className="row-form">
                        <FormInputText
                            name="lastName"
                            label="Nom"
                            control={control}
                            rules={{ required: true }}
                        />
                    </div>

                    <div className="row-form">
                        <FormAutocomplete
                            name={"role"}
                            label={"ROLE"}
                            control={control}
                            rules={{ required: true }}
                            options={typeRole}
                        />
                    </div>

                    <div className="row-form">
                        <FormInputText
                            name="password"
                            label="Mot de passe"
                            control={control}
                            rules={{ required: false }}
                            type="password"
                        />
                    </div>

                </div>

                <div className="btn-container">

                    <Button 
                        variant="text"
                        color="grey"
                        size="small"
                        className="cancel"
                        onClick={handleClosePopup}
                    >
                        Annuler
                    </Button>

                    <FormButtonSubmit loading={loading} />

                </div>
            </form>
        </div>
    )
}