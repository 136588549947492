import { useEffect, useState, useContext } from "react";
import "./Moovie.scss";
import { getListProject } from "../../store/index.service";
import { HeaderList } from "../../components/HeaderList/HeaderList";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { faFilm } from "@fortawesome/free-solid-svg-icons";
import { Button, TextField } from "@mui/material";
import { StoreContext } from "../../store/store";
import { Popup } from "../../components/Popup/Popup";
import { SendEmail } from "../../components/Popup/SendEmail/SendEmail";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const Moovie = () => {
	const { lang } = useContext(StoreContext);
	const [showPopupSendEmail, setShowPopupSendEmail] = useState({ show: false });
	const [selectedCards, setSelectedCards] = useState([]);
	const [Cards, setCards] = useState([]);

	const handleCardSelect = (card) => {
		if (selectedCards.some((selectedCard) => selectedCard.id === card.id)) {
			setSelectedCards(
				selectedCards.filter((selectedCard) => selectedCard.id !== card.id)
			);
		} else {
			setSelectedCards([...selectedCards, card]);
		}
	};

	useEffect(() => {
		handleGetData();
	}, []);

	const handleGetData = () => {
		getListProject().then((res) => {
			setCards(res.data);
		});
	};

	const handleCommentChange = (cardId, comment, dataKey) => {
		setSelectedCards((prevSelectedCards) =>
		prevSelectedCards.map((selectedCard) =>
			selectedCard.id === cardId
			? { ...selectedCard, [dataKey]: comment}
			: selectedCard
		));
	};

	return (
		<div className="list pageMovie">
			<HeaderList title="Selectionnez les vidéos à envoyer" icon={faFilm}>
				{selectedCards && selectedCards.length > 0 && (
				<>
					<p>{selectedCards.length} Sélectionnée</p>
					<Button variant="contained" onClick={() => setShowPopupSendEmail({ show: true })}>Suivant</Button>
				</>
				)}
			</HeaderList>

			<Breadcrumb links={[{ 
				label: (
					<>
					Vous pouvez faire votre propre sélection en cliquant sur chaque programme.<br />
					Vous pouvez les conserver pour vous, les envoyer à vos collègues ou à votre propre adresse e-mail.
					</>
				)
			}]} />

			<div className="grid-container">
				{Cards.map((card) => (
					<div className="grid-container-all">

						{selectedCards.some((selectedCard) => selectedCard.id === card.id) && (
							<div className="text-input-conteneur">
								<TextField
									id={`comment-${card.id}`}
									className="standard-multiline-static"
									label="Message"
									multiline
									rows={5}
									variant="filled"
									value={card.firstMessage}
									onChange={(e) => handleCommentChange(card.id, e.target.value, "firstMessage")}
								/>
							</div>
						)}

						<div
							key={card[lang].id}
							className={`card ${selectedCards.some((selectedCard) => selectedCard.id === card.id) ? "selected" : ""}`}
							onClick={() => handleCardSelect(card)}
						>
							{card[lang].background.data && card[lang].background.data.path && (
								<img src={card[lang].background.data.path} alt={card[lang].title.data} />
							)}
							<div>
								<h3>{card[lang].title.data}</h3>
							</div>
						</div>

						{selectedCards.some((selectedCard) => selectedCard.id === card.id) && (
							<div className="text-input-conteneur">
								<h3>Ajouter Votre commentaire</h3>
								<CKEditor
									editor={ClassicEditor}
									data={card[lang].vignette.data}
									onReady={(editor) => {
										handleCommentChange(card.id, card[lang].vignette.data, "commentaire");
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										handleCommentChange(card.id, data, "commentaire");
									}}
								/>
								<TextField
									id={`comment-${card.id}`}
									className="standard-multiline-static"
									label="Message secondaire"
									multiline
									rows={5}
									variant="filled"
									value={card.lastMessage}
									onChange={(e) => handleCommentChange(card.id, e.target.value, "lastMessage")}
								/>
							</div>
						)}
					</div>
				))}
			</div>

			{showPopupSendEmail.show && (
				<Popup
					closePopup={() => setShowPopupSendEmail({ show: false })}
					customContent={true}
				>
				<SendEmail
					closePopup={() => setShowPopupSendEmail({ show: false })}
					refreshData={() => handleGetData()}
					dataStore={selectedCards}
					lang={lang}
				/>
				</Popup>
			)}
		</div>
	);
};
