import { useState, useEffect, useContext } from "react";
import './footer.scss';
import { getOneMenu, deleteMenu, updateStatusMenu, updateOrderMenu, getOneConfig, updateConfig } from '../../../store/index.service';
import { HeaderList } from '../../../components/HeaderList/HeaderList';
import { Breadcrumb } from '../../../components/Breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTableColumns, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { Popup } from "../../../components/Popup/Popup";
import { ConfirmDelete } from "../../../components/Popup/content/ConfirmDelete";
import { AddMenu } from "../../../components/Popup/menu/AddMenu";
import { UpdateItemMenu } from "../../../components/Popup/menu/UpdateItemMenu";
import { generateMainForm, generateAsideForm } from "../../../helpers";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../../components/Form/FormInputText";
import { FormCKEditor } from "../../../components/Form/FormCKEditor";
import { AddImgFromGallery } from "../../../components/AddImgFromGallery/AddImgFromGallery";
import { FormAutocomplete } from "../../../components/Form/FormAutocomplete";
import { FormButtonSubmit } from "../../../components/Form/FormButtonSubmit";
import { StoreContext } from "../../../store/store";
import { Button } from "@mui/material";
import { NotificationManager } from "react-notifications";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const Footer = () => {

    const { control, watch, setValue, handleSubmit } = useForm();
    const { lang } = useContext(StoreContext);
    const [menuFooter, setMenuFooter] = useState({ loading: true, data: [] });
    const [configFooter, setConfigFooter] = useState({ loading: true, data: [] });
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({ show: false });
    const [showPopupAddMenu, setShowPopupAddMenu] = useState({ show: false });
    const [showPopupUpdateItemMenu, setShowPopupUpdateItemMenu] = useState({ show: false });
    const [loading, setLoading] = useState(false);

    const columns = [
        { field: 'title', headerName: 'Titre', flex: 1.5, valueGetter: (params) => params.row[lang].title },
        { field: 'idPage', headerName: 'Page', flex: .4, align: "center", headerAlign: "center" },
        { field: 'url', headerName: 'URL', flex: 1.5, valueGetter: ({ row }) => row.externalLink ? row.url : row.pages.data && row.pages.data[lang].slug.data },
        { field: 'parents', headerName: 'Parent', flex: 1.5, valueGetter: ({ row }) => row.parents && row.parents[lang].title },
        { field: 'externalLink', headerName: 'Lien externe', flex: 0.66, align: "center", headerAlign: "center" },
        { field: 'status', headerName: 'Publié', flex: 0.4, type: "boolean", sortable: false, renderCell: (params) => (
            <label className="switch">
                <input type="checkbox" onChange={() => handleStatus(params.row.id)} checked={params.row.status} />
                <span className="slider round"></span>
            </label>
        )},
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => setShowPopupUpdateItemMenu({ show: true, id: params.row.id })}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    const onSubmit = data => {
        setLoading(true);
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
            if(value && value.type === "image")
                if(value.data)
                    formData.append(key, value.data.id);
                else
                    formData.append(key, null);
            else
                formData.append(key, value);
        }

        updateConfig(formData, 2)
            .then(() => {
                handleGetData();
                NotificationManager.success("", "Footer modifié");
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error("", "Une erreur est survenue");
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {

        setMenuFooter({
            ...menuFooter,
            loading: true
        });

        getOneMenu("footer").then(res => {
            setMenuFooter({
                loading: false,
                data: res.menu ?? []
            });
        });

        getOneConfig(2).then(res => {
            setConfigFooter({
                loading: false,
                data: res.config[0] ?? []
            });

            if(res.config[0])
                for(const [key, value] of Object.entries(res.config[0])) {
                    if(value && value.type === "image")
                        setValue(key, value);
                    else
                        setValue(key, value.data);
                }
        });
    }  

    const handleDelete = () => {
        deleteMenu(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    const handleStatus = (id) => {
        updateStatusMenu(id).then(() => {
            handleGetData();
        });
    }

    const handleRowOrderChange = (params) => {

        const { oldIndex, targetIndex } = params;

        const rowsClone = [...menuFooter.data];
        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);

        updateOrderMenu(rowsClone, 'footer')
            .catch(err => {
                console.error(err);
                NotificationManager.error("Nous n'avons pas pu changer l'ordre", "Une erreur est survenue");
            })
            .finally(() => {
                handleGetData();
            })
    }

    const handleForm = (res) => {
        let form = [];

        for (const [key, value] of Object.entries(res)) {
            if(value.type === "string"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormInputText
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if(value.type === "text"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormCKEditor
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if(value.type === "image"){
                form.push(
                    <div className="row-form" key={key}>
                        <AddImgFromGallery
                            value={watch(key) ? watch(key).data : null}
                            chooseImg={(img) => setValue(key, { type: 'image', data: img })}
                        />
                    </div>
                )
            } else if(value.type === "select"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormAutocomplete
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if(value.type === "list"){
                form.push(
                    <DataGridPro
                        autoHeight
                        rows={value.data}
                        columns={columns}
                        pageSize={20}
                        className="table"
                        pagination
                        pageSizeOptions={[20, 50, 100]}
                    />
                )
            }
        }

        return form;
    }

    return (
        <div className="list">

            <HeaderList title="Footer" icon={faTableColumns}>
                <Button variant="contained" onClick={() => setShowPopupAddMenu({ show: true })}>Ajouter un lien</Button>
            </HeaderList>

            <Breadcrumb
                links={[
                    { label: "Templates" },
                    { label: "Footer" }
                ]}
            />

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="panel">

                    <div className="contents">
                        {handleForm(generateMainForm(configFooter.data ?? []))}

                        <DataGridPro
                            autoHeight
                            rows={menuFooter.data}
                            columns={columns}
                            pageSize={20}
                            className="table"
                            loading={menuFooter.loading}
                            pagination
                            pageSizeOptions={[20, 50, 100]}
                            rowReordering
                            onRowOrderChange={handleRowOrderChange}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                        />
                    </div>

                    <div className="aside-right">

                        <div className="row-form">
                            <FormButtonSubmit fullWidth loading={loading} />
                        </div>

                        {handleForm(generateAsideForm(configFooter.data ?? []))}

                    </div>

                </div>

            </form>

            { showPopupAddMenu.show &&
                <Popup
                    closePopup={() => setShowPopupAddMenu({ show: false })}
                    customContent={true}
                >
                    <AddMenu
                        closePopup={() => setShowPopupAddMenu({ show: false })}
                        type="footer"
                        refreshData={handleGetData}
                    />
                </Popup>
            }

            { showPopupUpdateItemMenu.show &&
                <Popup
                    closePopup={() => setShowPopupUpdateItemMenu({ show: false })}
                    customContent={true}
                >
                    <UpdateItemMenu
                        closePopup={() => setShowPopupUpdateItemMenu({ show: false })}
                        type="footer"
                        id={showPopupUpdateItemMenu.id}
                        refreshData={handleGetData}
                    />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete
                        msg={"ce lien"}
                        handleDelete={handleDelete}
                    />
                </Popup>
            }

        </div>
    )
}