import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getListSectionPage = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/sectionpage/' + id, {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data);
}

export const getOneSectionPage = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/sectionpage/' + id , {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data.data);
}

export const addSectionToPage = async (data, id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).post('api/v1/admin/sectionpage/' + id , data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const deleteSectionPage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).delete('api/v1/admin/sectionpage/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateStatusSectionPage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).put('api/v1/admin/status/sectionpage/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateOrderSectionPage = (data, id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).put('api/v1/admin/order/sectionpage/' + id, {data})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}


