import { useState, useEffect, useContext } from "react";
import './page.scss';
import { getListSectionPage, deleteSectionPage, updateStatusSectionPage, updateOrderSectionPage } from '../../store/index.service';
import { useNavigate, useParams } from "react-router-dom";
import { HeaderList } from '../../components/HeaderList/HeaderList';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { Popup } from "../../components/Popup/Popup";
import { ConfirmDelete } from "../../components/Popup/content/ConfirmDelete";
import { FormPage } from "../../components/Form/FormPage";
import { CreateSection } from "../../components/Popup/section/CreateSection";
import { CreateTemplate } from "../../components/Popup/section/CreateTemplate";
import { AddSection } from "../../components/Popup/section/AddSection";
import { StoreContext } from "../../store/store";
import { Button } from "@mui/material";
import { NotificationManager } from "react-notifications";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const PageSection = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const { lang } = useContext(StoreContext);

    const [listSectionPage, setListSectionPage] = useState({loading: true, data: []});
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
    const [showPopupCreateSection, setShowPopupCreateSection] = useState({show: false});
    const [showPopupCreateTemplate, setShowPopupCreateTemplate] = useState({show: false});
    const [showPopupAddSection, setShowPopupAddSection] = useState({show: false});

    const columns = [
        { field: 'title', headerName: 'Titre', flex: 1.5, valueGetter: (params) => params.row[lang].title.data },
        { field: 'template', headerName: 'Template', flex: .75 },
        { field: 'status', headerName: 'Publié', flex: 0.4, type: "boolean", sortable: false, renderCell: (params) => (
            <label className="switch">
                <input type="checkbox" onChange={() => handleStatus(params.row.id)} checked={params.row.status} />
                <span className="slider round"></span>
            </label>
        )},
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => navigate(`/page/${id}/section/update/${params.row.idSection}`)}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {

        setListSectionPage({
            ...listSectionPage,
            loading: true
        })

        getListSectionPage(id).then(res => {
            setListSectionPage({
                loading: false,
                data: res.data ?? []
            });
        });
    }  

    const handleStatus = (id) => {
        updateStatusSectionPage(id).then(() => {
            handleGetData();
        });
    }

    const handleDelete = () => {
        deleteSectionPage(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    const handleRowOrderChange = (params) => {

        const { oldIndex, targetIndex } = params;

        const rowsClone = [...listSectionPage.data];
        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);

        updateOrderSectionPage(rowsClone, id)
            .catch(err => {
                console.error(err);
                NotificationManager.error("Nous n'avons pas pu changer l'ordre", "Une erreur est survenue");
            })
            .finally(() => {
                handleGetData();
            })
    }

    return (
        <div className="list">
            
            <HeaderList title="Editer une page" icon={faNewspaper}>
                <Button variant="contained" onClick={() => setShowPopupAddSection({ show: true })}>Ajouter une section</Button>
                <Button variant="contained" onClick={() => setShowPopupCreateSection({ show: true })}>Créer une section</Button>
                <Button variant="contained" onClick={() => setShowPopupCreateTemplate({ show: true })}>Créer un template</Button>
            </HeaderList>

            <Breadcrumb
                links={[
                    { label: "Pages", to: "/page" },
                    { label: "Editer une page" }
                ]}
            />

            <div className="contents-page">
                
                <DataGridPro
                    autoHeight
                    rows={listSectionPage.data}
                    columns={columns}
                    pageSize={20}
                    className="table"
                    loading={listSectionPage.loading}
                    pagination
                    pageSizeOptions={[20, 50, 100]}
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />

                <FormPage id={id} />

            </div>

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete
                        msg={"cette section"}
                        handleDelete={handleDelete}
                    />
                </Popup>
            }

            { showPopupCreateSection.show &&
                <Popup
                    closePopup={() => setShowPopupCreateSection({ show: false })}
                    customContent={true}
                >
                    <CreateSection 
                        closePopup={() => setShowPopupCreateSection({ show: false })}
                        refreshData={() => handleGetData()} 
                    />
                </Popup>
            }

            { showPopupCreateTemplate.show &&
                <Popup
                    closePopup={() => setShowPopupCreateTemplate({ show: false })}
                    customContent={true}
                >
                    <CreateTemplate 
                        closePopup={() => setShowPopupCreateTemplate({ show: false })} 
                        refreshData={() => handleGetData()}
                    />
                </Popup>
            }

            { showPopupAddSection.show &&
                <Popup
                    closePopup={() => setShowPopupAddSection({ show: false })}
                    customContent={true}
                >
                    <AddSection 
                        closePopup={() => setShowPopupAddSection({ show: false })} id={id} 
                        refreshData={() => handleGetData()}
                    />
                </Popup>
            }
        </div>
    )
}