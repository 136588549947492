import { useState, useContext } from 'react';
import './login.scss';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { StoreContext } from '../../store/store';
import ClipLoader from "react-spinners/ClipLoader";
import { FormInputText } from "../../components/Form/FormInputText";
import { Button } from '@mui/material';

import { signIn } from "../../store/index.service";

export const Login = () => {

    const { setLoggedIn } = useContext(StoreContext);

    const { handleSubmit, control } = useForm();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const onSubmit = (data) => {
        setLoading(true);
        
        signIn(data)
            .then(res => {
                Cookies.set('loggedIn', true, { expires: 1 });
                localStorage.setItem('token', res.data.token);
                setLoggedIn(true);
            })
            .catch(err => {
                console.error(err);
                setError(
                    err.response.status === 401 ? err.response.status : "default"
                );
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className="login">

            <div className='login-header'>
                <h1>
                Superights
                </h1>
            </div>

            <form name="login" className="register-form" onSubmit={handleSubmit(onSubmit)}>

                { error &&
                    <div className='error-msg'>
                        <span>
                            Erreur
                        </span>
                    </div>
                }

                <FormInputText
                    name="username"
                    control={control}
                    rules={{ required: true }}
                    label="Email"
                    type="email"
                />

                <FormInputText
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    label="Mot de passe"
                    type="password"
                />

                <Button 
                    variant="contained"
                    disableElevation
                    type="submit"
                    size='large'
                >
                    <ClipLoader
                        color={"#fff"}
                        loading={loading}
                        size={20}
                        cssOverride={{ marginRight: 5 }}
                    />
                    Connexion
                </Button>
                
            </form>

        </div>
    )
}