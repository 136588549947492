import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getListPage = async () => {
    const result = await axios(config.baseUrl + 'api/v1/admin/pages', {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data);
}

export const getOnePage = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/pages/' + id , {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data.page[0]);
}

export const createPage = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).post('api/v1/admin/pages', data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updatePage = async (data, id, lang) => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined,
                'Content-Type': 'application/json',
                "Accept-Language": lang
            }
        }).post(`/admin/pages/${id}`, data);
    });
}

export const deletePage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).delete('api/v1/admin/pages/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}
