import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getListMenu = async () => {
    const result = await axios(config.baseUrl + 'api/v1/admin/menu', {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data);
}

export const getOneMenu = async (type) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/menu/' + type, {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data);
}

export const getOneItemMenu = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/menu/item/' + id, {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data.menu[0]);
}

export const createMenu = (data, type) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).post('api/v1/admin/menu/' + type, data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateMenu = async (data, type, id, lang) => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined,
                'Content-Type': 'application/json',
                "Accept-Language": lang
            }
        }).post(`/admin/menu/${type}/${id}`, data);
    });
}

export const deleteMenu = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).delete('api/v1/admin/menu/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateStatusMenu = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).post('api/v1/admin/status/menu/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateOrderMenu = (data, type) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).put('api/v1/admin/order/menu/' + type, {data})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}


