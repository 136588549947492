import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { updateSection, getOneSection, deleteItem, updateStatusItem, updateOrderItem } from '../../store/index.service';
import { FormInputText } from "../../components/Form/FormInputText";
import { FormAutocomplete } from "../../components/Form/FormAutocomplete";
import { FormCKEditor } from "../../components/Form/FormCKEditor";
import { FormButtonSubmit } from "../../components/Form/FormButtonSubmit";
import { FormButton } from "../../components/Form/FormButton";
import { FormToggle } from "../../components/Form/FormToggle";
import { FormSwitch } from "../../components/Form/FormSwitch";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { Button } from "@mui/material";
import { Popup } from "../../components/Popup/Popup";
import { AddField } from "../../components/Popup/content/AddField";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { ConfirmDelete } from "../../components/Popup/content/ConfirmDelete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { AddImgFromGallery } from "../../components/AddImgFromGallery/AddImgFromGallery";
import { generateMainForm, generateAsideForm } from "../../helpers";
import { StoreContext } from "../../store/store";
import { HeaderList } from "../../components/HeaderList/HeaderList";
import { NotificationManager } from "react-notifications";


const CustomToolbar = ({ idSection, item, navigate, idPage }) => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <Button size="small" onClick={() => navigate(`/page/${idPage}/section/${idSection}/${item}/create`)} className="btn btn-primary">Ajouter un item</Button>
        </GridToolbarContainer>
    );
}

export const UpdateSection = () => {

    let { id, idPage } = useParams();

    const { handleSubmit, control, setValue, watch } = useForm();

    const navigate = useNavigate();

    const { lang } = useContext(StoreContext);

    const [dataForm, setDataForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingFormData, setLoadingFormData] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showPopupAddField, setShowPopupAddField] = useState({ show: false });
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});

    const onSubmit = data => {

        setLoading(true);
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
            if (value && value.type === "image")
                if (value.data)
                    formData.append(key, value.data.id);
                else
                    formData.append(key, null);
            else if (typeof value === "object" && value)
                formData.append(key, JSON.stringify(value));
            else if (key.includes("btn_"))
                continue;
            else
                formData.append(key, value);
        }

        if (Object.keys(data).includes("btn_title")) {
            formData.append("button", JSON.stringify({
                title: data.btn_title,
                url: data.btn_url,
                status: data.btn_status
            }));
        }

        updateSection(formData, id, lang, idPage)
            .then(() => {
                NotificationManager.success("", "Section modifiée");
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error("", "Une erreur est survenue");
                setErrorMsg("Une erreur est survenue");
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        handleGetData();
    }, []);

    useEffect(() => {

        if(dataForm && dataForm[lang]) {
            for (const [key, value] of Object.entries(dataForm[lang])) {
                if (value && value.type === "image")
                    setValue(key, value);
                else if (value && value.type === "button") {
                    setValue("btn_title", value.title.data);
                    setValue("btn_url", value.url.data);
                    setValue("btn_status", value.status.data);
                } else
                    setValue(key, value.data);
            }

            setValue("status", dataForm.status);
            setValue("bgColor", dataForm.bgColor);
        }

    }, [dataForm, lang])

    const handleGetData = () => {
        getOneSection(id).then(res => {
            setDataForm(res);
            setLoadingFormData(false);
        });
    }  

    const handleDelete = () => {
        deleteItem(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    const handleStatus = (id) => {
        updateStatusItem(id).then(() => {
            handleGetData();
        });
    }

    const handleRowOrderChange = (params, list) => {

        const { oldIndex, targetIndex } = params;

        const rowsClone = [...list];
        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        
        updateOrderItem(rowsClone, id)
            .then(() => {
                setLoadingFormData(true);
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error("Nous n'avons pas pu changer l'ordre", "Une erreur est survenue");
            })
            .finally(() => {
                handleGetData();
            })
    }

    const columns = [
        { field: 'title', headerName: 'Nom', flex: 1, valueGetter: ({ row }) => {
            const {
                title = {},
                firstName = {},
                lastName = {},
                type = {}
            } = row[lang] ?? {};

            return title.data ?
                `${title.data}`
            : firstName.data ?
                `${firstName.data} ${lastName.data}`
            : type.data ?
                `${type.data}`
            : ""
        }},
        { field: 'status', headerName: 'Publié', flex: 0.4, type: "boolean", sortable: false, renderCell: (params) => (
            <label className="switch">
                <input type="checkbox" onChange={() => handleStatus(params.row.id)} checked={params.row.status} />
                <span className="slider round"></span>
            </label>
        )},
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => navigate(`/page/${idPage}/section/update/${id}/item/${params.row.id}`)}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    const handleForm = (res) => {
        let form = [];

        for (const [key, value] of Object.entries(res)) {
            if(value.type === "string"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormInputText
                            name={key}
                            label={key}
                            control={control}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if(value.type === "text"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormCKEditor
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if(value.type === "image"){
                form.push(
                    <div className="row-form" key={key}>
                        <AddImgFromGallery
                            value={watch(key) ? watch(key).data : null }
                            chooseImg={(img) => setValue(key, { type: 'image', data: img })}
                        />
                    </div>
                )
            } else if(value.type === "select"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormAutocomplete
                            name={key}
                            label={key}
                            control={control}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if (value.type === "list"){
                form.push(
                    <DataGridPro
                        autoHeight
                        rows={value.data ?? []}
                        loading={loadingFormData}
                        columns={columns}
                        pageSize={20}
                        className="table"
                        pagination
                        pageSizeOptions={[20, 50, 100]}
                        rowReordering
                        onRowOrderChange={(params) => handleRowOrderChange(params, value.data)}
                        slots={{
                            toolbar: (params) => <CustomToolbar idSection={id} item={key} navigate={navigate} idPage={idPage} />
                        }}
                        key={key}
                    />
                )
            } else if (value.type === "integer") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormInputText
                            name={key}
                            label={key}
                            control={control}
                            rules={{ required: false }}
                            type="number"
                        />
                    </div>
                )
            } else if (value.type === "button") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormButton
                            control={control}
                        />
                    </div>
                )
            } else if (value.type === "checkbox") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormSwitch
                            control={control}
                            name={key}
                            label={key}
                            labelPlacement="start"
                            sx={{
                                marginLeft: 0
                            }}
                        />
                    </div>
                )
            }
        }

        return form;
    }

    return (
        <div className="container-full">

            <div className="content">

                <HeaderList title="Editer une section" icon={faNewspaper}>
                    <Button variant="contained" onClick={() => setShowPopupAddField({ show: true })}>Ajouter un champ</Button>
                </HeaderList>

                <Breadcrumb
                    links={[
                        { label: "Pages", to: "/page" },
                        { label: "Editer une page", to: `/page/${idPage}/view` },
                        { label: "Editer une section" }
                    ]}
                />

                <div className="form">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="panel">
                            <div className="contents">
                                {dataForm[lang] && handleForm(generateMainForm(dataForm[lang]))}
                            </div>

                            <div className="aside-right">

                                {errorMsg && <p className="error-msg">{errorMsg}</p> }

                                <div className="row-form">
                                    <FormButtonSubmit fullWidth loading={loading} />
                                </div>

                                <div className="row-form">
                                    <div className="form-group">

                                        <FormSwitch
                                            control={control}
                                            name="status"
                                            label={"Publié"}
                                            labelPlacement="start"
                                            sx={{
                                                marginLeft: 0
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="row-form">
                                    <div className="form-group">

                                        <label>Arrière-plan &nbsp; &nbsp;</label>

                                        <FormToggle
                                            control={control}
                                            name="bgColor"
                                            items={[
                                                { value: "black", label: "Noir" },
                                                { value: "white", label: "Blanc" },
                                                { value: "rose", label: "Rose" }
                                            ]}
                                        />

                                    </div>
                                </div>

                                {dataForm[lang] && handleForm(generateAsideForm(dataForm[lang]))}

                            </div>

                        </div>

                    </form>

                </div>
            </div>

            { showPopupAddField.show &&
                <Popup
                    closePopup={() => setShowPopupAddField({ show: false })}
                    customContent={true}
                >
                    <AddField id = {id} type = "section" />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete msg={"cette item"} handleDelete={handleDelete} />
                </Popup>
            }
        </div>
    )
}