import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import { FormInputText } from '../../Form/FormInputText';
import { FormButtonSubmit } from "../../Form/FormButtonSubmit";
import { FormCheckbox } from "../../Form/FormCheckbox";
import { updateMenu, getOneItemMenu, getListPage, getOneMenu } from '../../../store/index.service';
import { FormAutocomplete } from "../../Form/FormAutocomplete";
import { StoreContext } from "../../../store/store";
import { NotificationManager } from "react-notifications";

export const UpdateItemMenu = ({ handleClosePopup, refreshData = () => {}, id, type }) => {

    const { handleSubmit, control, watch, setValue } = useForm();

    const { lang } = useContext(StoreContext);

    const [loading, setLoading] = useState(false);
    // const [errorMsg, setErrorMsg] = useState("");

    const INIT_LIST = {
        loading: true,
        data : []
    }
    
    const [listPage, setListPage] = useState(INIT_LIST);
    const [listMenu, setListMenu] = useState(INIT_LIST);

    useEffect(() => {

        Promise.all([
            getListPage(),
            getOneMenu(type)
        ])
        .then(([{ page }, { menu }]) => {
            console.log(page, menu);

            setListPage({
                loading: false,
                data: page ?? []
            });

            setListMenu({
                loading: false,
                data: menu ?? []
            });

            getOneItemMenu(id).then(res => {

                setValue("title", res[lang].title);
                setValue("url", res.url);
                setValue("page", page.find(el => el.id === res.idPage));
                setValue("parents", res.parents);
                setValue("externalLink", res.externalLink);
            });
        })

    }, []);

    const onSubmit = data => {

        setLoading(true);

        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data)) {
            if(key === "parents" && value)
                formData.append(key, value.id);
            else if(typeof value === "object")
                formData.append(key, JSON.stringify(value));
            else
                formData.append(key, value);
        }

        updateMenu(formData, type, id, lang)
            .then(() => {
                refreshData();
                handleClosePopup();
                NotificationManager.success("", "Menu modifié");
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                NotificationManager.error("", "Une erreur est survenue");

                // if(err.response.status === 409)
                //     setErrorMsg(err.response.data.result);
                // else
                //     setErrorMsg("Une erreur est survenue");
            })
    }

    return (
        <div className='popupCreateHorseQuick'>

            <h2>Editer un lien du menu</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                    
                    <div className="row-form">
                        <FormInputText
                            name="title"
                            label="Titre"
                            control={control}
                            defaultValue="titre"
                            rules={{ required: true }}
                        />
                    </div>

                    <div className="row-form">
                        <FormCheckbox
                            control={control}
                            data={[
                                {
                                    name: "externalLink",
                                    label: "Lien externe"
                                }
                            ]}
                        />
                    </div>

                    <div className="row-form">
                        
                        {watch("externalLink") ?
                            <FormInputText
                                name="url"
                                label="URL"
                                control={control}
                            />
                        :
                            <FormAutocomplete
                                name="page"
                                control={control}
                                label="Page"
                                options={listPage.data}
                                getOptionLabel={el => el.data[lang].title.data}
                                renderOption={(props, option) =>
                                    <li {...props} key={option.data[lang].title.data}>
                                        {option.data[lang].title.data}
                                    </li>
                                }
                            />
                        }

                    </div>

                    <div className="row-form">
                        <FormAutocomplete
                            name="parents"
                            control={control}
                            label="Se place sous"
                            options={listMenu.data.filter(el => el.id !== id)}
                            getOptionLabel={el => el[lang].title}
                            isOptionEqualToValue={el => el.idPage}
                            renderOption={(props, option) =>
                                <li {...props} key={option[lang].title}>
                                    {option[lang].title}
                                </li>
                            }
                            clearable
                        />
                    </div>

                </div>

                <div className="btn-container">

                    <Button 
                        variant="text"
                        color="grey"
                        size="small"
                        className="cancel"
                        onClick={handleClosePopup}
                    >
                        Annuler
                    </Button>

                    <FormButtonSubmit loading={loading} />

                </div>
            </form>
        </div>
    )
}