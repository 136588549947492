import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateItem, getOneItem, updateStatusItem, deleteItem, getListItemByTitle, updateOrderItem } from '../../../store/index.service';
import { FormInputText } from "../../../components/Form/FormInputText";
import { FormAutocomplete } from "../../../components/Form/FormAutocomplete";
import { FormCKEditor } from "../../../components/Form/FormCKEditor";
import { FormButtonSubmit } from "../../../components/Form/FormButtonSubmit";
import { FormButton } from "../../../components/Form/FormButton";
import { FormSwitch } from "../../../components/Form/FormSwitch";
import { Breadcrumb } from "../../../components/Breadcrumb/Breadcrumb";
import { Switch, FormControlLabel, Button } from "@mui/material";
import { Popup } from "../../../components/Popup/Popup";
import { AddField } from "../../../components/Popup/content/AddField";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { ConfirmDelete } from "../../../components/Popup/content/ConfirmDelete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { AddImgFromGallery } from "../../../components/AddImgFromGallery/AddImgFromGallery";
import { StoreContext } from "../../../store/store";
import { generateMainForm, generateAsideForm } from "../../../helpers";
import { NotificationManager } from "react-notifications";
import { HeaderList } from "../../../components/HeaderList/HeaderList";


const CustomToolbar = ({ idSection, item, idParent, navigate, idPage }) => {

    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <Button onClick={() => navigate(`/page/${idPage}/section/${idSection}/${item}/create/${idParent}`)} className="btn btn-primary">Ajouter un item</Button>
        </GridToolbarContainer>
    );
}

export const UpdateItem = () => {

    let { id, idElement, idPage, idParent } = useParams();

    const { handleSubmit, control, setValue, watch } = useForm();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { lang } = useContext(StoreContext);

    const [loadingFormData, setLoadingFormData] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showPopupAddField, setShowPopupAddField] = useState({ show: false });
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
    const [listItem, setListItem] = useState({loading: true, data: []});

    const columns = [
        { field: 'title', headerName: 'Nom', flex: 1, valueGetter: ({ row }) => {
            const {
                title = {},
                firstName = {},
                lastName = {},
                type = {}
            } = row[lang] ?? {};

            return title.data ?
                `${title.data}`
            : firstName.data ?
                `${firstName.data} ${lastName.data}`
            : type.data ?
                `${type.data}`
            : ""
        }},
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => navigate(`/page/${idPage}/section/${params.row.parents}/${params.row.title}/update/${params.row.parents}/${params.row.id}`)}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    const onSubmit = data => {

        setLoading(true);
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
            if(value && value.type === "image")
                if(value.data)
                    formData.append(key, value.data.id);
                else
                    formData.append(key, null);
            else if (typeof value === "object" && value)
                formData.append(key, JSON.stringify(value));
            else
                formData.append(key, value);
        }

        var newId = id;

        if(idElement){
            newId = idElement;
        }

        updateItem(formData, newId, lang)
            .then(() => {
                handleGetData();
                NotificationManager.success("Modifications enregistrées");
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error("", "Une erreur est survenue");
                setErrorMsg("Une erreur est survenue");
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        handleGetData();
    }, [pathname]);

    useEffect(() => {

        if(dataForm && dataForm[lang]) {

            for (const [key, value] of Object.entries(dataForm[lang])) {
                if(value && value.type === "image")
                    setValue(key, value);
                else if (value && value.type === "select")
                    hangleGetListItem(key);
                else if (value && value.type === "button") {
                    setValue("btn_title", value.title.data);
                    setValue("btn_url", value.url.data);
                    setValue("btn_status", value.status.data);
                } else
                    setValue(key, value.data);
            }

            setValue("status", dataForm.status);
        }

    }, [dataForm, lang])

    const handleGetData = () => {
        getOneItem(idElement ?? id).then(res => {
            setDataForm(res);
            setLoadingFormData(false);
        });
    }

    const handleDelete = () => {
        deleteItem(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    const hangleGetListItem = (key) => {
        getListItemByTitle(key).then(res => {
            setListItem({
                loading: false,
                data: res.data ?? []
            });
        });
    }

    const handleRowOrderChange = (params, list) => {
        const { oldIndex, targetIndex } = params;
        const rowsClone = [...list];
        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        
        updateOrderItem(rowsClone, id)
            .then(() => {
                setLoadingFormData(true);
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error("Nous n'avons pas pu changer l'ordre", "Une erreur est survenue");
            })
            .finally(() => {
                handleGetData();
            })
    }

    const handleForm = (res) => {
        let form = [];

        for (const [key, value] of Object.entries(res)) {
            if(value.type === "string"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormInputText
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if(value.type === "text"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormCKEditor
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                        />
                    </div>
                )
            } else if(value.type === "image"){
                form.push(
                    <div className="row-form" key={key}>
                        <AddImgFromGallery
                            value={watch(key) ? watch(key).data : null}
                            chooseImg={(img) => setValue(key, { type: 'image', data: img })}
                        />
                    </div>
                )
            } else if(value.type === "select"){
                form.push(
                    <div className="row-form" key={key}>
                        <FormAutocomplete
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: true }}
                        />
                    </div>
                )
            } else if(value.type === "list"){
                form.push(
                    <div className="row-form" key={key}>
                        <p>{key}</p>
                        <DataGridPro
                            autoHeight
                            rows={value.data}
                            columns={columns}
                            pageSize={20}
                            className="table"
                            rowReordering
                            onRowOrderChange={(params) => handleRowOrderChange(params, value.data)}
                            pagination
                            pageSizeOptions={[20, 50, 100]}
                            slots={{
                                toolbar: (params) => <CustomToolbar idSection={id} item={key} idParent={id} navigate={navigate} idPage={idPage} />
                            }}
                        />
                    </div>
                )
            } else if (value.type === "integer") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormInputText
                            name={key}
                            label={key}
                            control={control}
                            defaultValue={value.data}
                            rules={{ required: false }}
                            type="number"
                        />
                    </div>
                )
            } else if (value.type === "button") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormButton
                            control={control}
                        />
                    </div>
                )
            } else if (value.type === "checkbox") {
                form.push(
                    <div className="row-form" key={key}>
                        <FormSwitch
                            control={control}
                            name={key}
                            label={key}
                            labelPlacement="start"
                            sx={{
                                marginLeft: 0
                            }}
                        />
                    </div>
                )
            }
        }

        return form;
    }

    return (
        <div className="container-full">

            <div className="content">

                <HeaderList title="Editer un item" icon={faNewspaper}>
                    <Button variant="contained" onClick={() => setShowPopupAddField({ show: true })}>Ajouter un champ</Button>
                </HeaderList>

                <Breadcrumb
                    links={[
                        { label: "Pages", to: "/page" },
                        { label: "Editer une page", to: `/page/${idPage}/view` },
                        { label: "Editer une section", to: `/page/${idPage}/section/update/${idElement ?? idParent}` },
                        { label: "Editer un item" }
                    ]}
                />

                <div className="form">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="panel">
                            <div className="contents">
                                {dataForm && dataForm[lang] && handleForm(generateMainForm(dataForm[lang]))}
                            </div>

                            <div className="aside-right">

                                {errorMsg &&
                                    <p className="error-msg">
                                        {errorMsg}
                                    </p>
                                }

                                <div className="row-form">
                                    <FormButtonSubmit loading={loading} fullWidth />
                                </div>

                                <div className="row-form">
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={"status"}
                                                control={control}
                                                render={({ field: { onChange, value } }) =>
                                                    <Switch
                                                        checked={!!value}
                                                        onChange={onChange}
                                                    />
                                                }
                                            />
                                        }
                                        label={!!watch("status") ? "Publié" : "Non-publié"}
                                    />
                                </div>

                                {dataForm && dataForm[lang] && handleForm(generateAsideForm(dataForm[lang]))}

                            </div>

                        </div>

                    </form>

                </div>
            </div>

            { showPopupAddField.show &&
                <Popup
                    closePopup={() => setShowPopupAddField({ show: false })}
                    customContent={true}
                >
                    <AddField id = {id} />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete msg={"cet item"} handleDelete={handleDelete} />
                </Popup>
            }

        </div>
    )
}