import { config } from "../config";
import axios from "axios";

export const getListProject = async () => {
  const result = await axios(config.baseUrl + "api/v1/admin/list-projet", {
    headers: {
      Authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : undefined,
    },
  });
  return result.data;
};

export const postProjectEmail = async (data) => {
  return await axios.post(config.baseUrl + "api/v1/admin/send-email", data, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : undefined,
      "Content-Type": "application/json",
    },
  });
};
