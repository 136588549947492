import { useState, useEffect, useContext } from "react";
import './headerEdit.scss';
import { getOneMenu, deleteMenu, updateStatusMenu, updateOrderMenu, getOneConfig, updateConfig } from '../../../store/index.service';
import { HeaderList } from '../../../components/HeaderList/HeaderList';
import { Breadcrumb } from '../../../components/Breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTableColumns, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { Popup } from "../../../components/Popup/Popup";
import { ConfirmDelete } from "../../../components/Popup/content/ConfirmDelete";
import { AddMenu } from "../../../components/Popup/menu/AddMenu";
import { UpdateItemMenu } from "../../../components/Popup/menu/UpdateItemMenu";
import { FormButtonSubmit } from "../../../components/Form/FormButtonSubmit";
import { AddImgFromGallery } from "../../../components/AddImgFromGallery/AddImgFromGallery";
import { useForm } from "react-hook-form";
import { StoreContext } from "../../../store/store";
import { Button } from "@mui/material";
import { NotificationManager } from "react-notifications";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const HeaderEdit = () => {

    const { handleSubmit } = useForm();
    const { lang } = useContext(StoreContext);
    const [menuHeader, setMenuHeader] = useState({ loading: true, data: [] });
    const [configHeader, setConfigHeader] = useState({});
    const [configLanguage, setConfigLanguage] = useState({loading: true, data: []});
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({ show: false });
    const [showPopupAddMenu, setShowPopupAddMenu] = useState({ show: false });
    const [showPopupUpdateItemMenu, setShowPopupUpdateItemMenu] = useState({ show: false });
    const [loading, setLoading] = useState(false);

    const columns = [
        { field: 'title', headerName: 'Titre', flex: 1.5, valueGetter: (params) => params.row[lang].title },
        { field: 'idPage', headerName: 'Page', flex: .4, align: "center", headerAlign: "center" },
        { field: 'url', headerName: 'Slug / URL', flex: 1.5, valueGetter: ({ row }) => row.externalLink ? row.url : row.pages.data && row.pages.data[lang].slug.data },
        { field: 'parents', headerName: 'Parent', flex: 1.5, valueGetter: ({ row }) => row.parents && row.parents[lang].title },
        { field: 'externalLink', headerName: 'Lien externe', flex: 0.66, align: "center", headerAlign: "center" },
        { field: 'status', headerName: 'Publié', flex: 0.4, type: "boolean", sortable: false, renderCell: (params) => (
            <label className="switch">
                <input type="checkbox" onChange={() => handleStatus(params.row.id)} checked={params.row.status} />
                <span className="slider round"></span>
            </label>
        )},
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => setShowPopupUpdateItemMenu({ show: true, id: params.row.id })}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    const onSubmit = data => {

        setLoading(true);

        const formData = new FormData();

        if(configHeader.image)
            formData.append("image", configHeader.image.data.id);

        updateConfig(formData, 1)
            .then(() => {
                handleGetData();
                NotificationManager.success("Header modifié");
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error("Une erreur est survenue");
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {

        setMenuHeader({
            ...menuHeader,
            loading: true
        })

        getOneMenu("header").then(res => {
            setMenuHeader({
                loading: false,
                data: res.menu ?? []
            });
        });

        getOneConfig(1).then(res => {
            setConfigHeader(res.config[0] ?? {});
        });

        getOneConfig(3).then(res => {
            setConfigLanguage({
                loading: false,
                data: res.config ?? []
            });
        });
    }  

    const handleDelete = () => {
        deleteMenu(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    const handleStatus = (id) => {
        updateStatusMenu(id).then(() => {
            handleGetData();
        });
    }

    const handleRowOrderChange = (params) => {

        const { oldIndex, targetIndex } = params;

        const rowsClone = [...menuHeader.data];
        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);

        updateOrderMenu(rowsClone, 'header')
            .catch(err => {
                console.error(err);
                NotificationManager.error("Nous n'avons pas pu changer l'ordre", "Une erreur est survenue");
            })
            .finally(() => {
                handleGetData();
            })
    }

    return (
        <div className="list">

            <HeaderList title="Header" icon={faTableColumns}>
                <Button variant="contained" onClick={() => setShowPopupAddMenu({ show: true })}>Ajouter un lien</Button>
            </HeaderList>

            <Breadcrumb
                links={[
                    { label: "Templates" },
                    { label: "Header" }
                ]}
            />

            <div className="contents-page">
                <DataGridPro
                    autoHeight
                    rows={menuHeader.data}
                    columns={columns}
                    pageSize={20}
                    className="table"
                    loading={menuHeader.loading}
                    pagination
                    pageSizeOptions={[20, 50, 100]}
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />

                <div className="aside-right">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row-form">
                            <FormButtonSubmit fullWidth loading={loading} />
                        </div>
                        
                        <div className="row-form">
                            <AddImgFromGallery
                                value={configHeader.image ? configHeader.image.data : undefined}
                                chooseImg={(img) => setConfigHeader({ ...configHeader, image: { type: 'image', data: img } })}
                            />
                        </div>
                    </form>
                </div>
            </div>

            { showPopupAddMenu.show &&
                <Popup
                    closePopup={() => setShowPopupAddMenu({ show: false })}
                    customContent={true}
                >
                    <AddMenu
                        closePopup={() => setShowPopupAddMenu({ show: false })}
                        type="header"
                        refreshData={handleGetData}
                    />
                </Popup>
            }

            { showPopupUpdateItemMenu.show &&
                <Popup
                    closePopup={() => setShowPopupUpdateItemMenu({ show: false })}
                    customContent={true}
                >
                    <UpdateItemMenu
                        closePopup={() => setShowPopupUpdateItemMenu({ show: false })}
                        type="header"
                        id={showPopupUpdateItemMenu.id}
                        refreshData={handleGetData}
                    />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete msg={"ce lien"} handleDelete={handleDelete} />
                </Popup>
            }

        </div>
    )
}