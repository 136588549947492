import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getListEmailSend } from '../../store/index.service';
import { HeaderList } from '../../components/HeaderList/HeaderList';
import { faNewspaper, faEye } from '@fortawesome/free-solid-svg-icons'
import { DataGridPro, GridToolbarContainer, GridToolbarFilterButton, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sanitizeHtml } from "../../helpers";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const EmailSend = () => {
    const navigate = useNavigate();
    const [listEmailSend, setListEmailSend] = useState({loading: true, data: []});

    const columns = [
        { field: 'object', headerName: 'Objet', flex: 1.5 },
        { field: 'mailTo', headerName: 'Envoyez à', flex: 1.5 },
        { field: 'createdAt', headerName: 'Envoyez le', flex: 1.5, type: 'dateTime', valueGetter: ({ value }) => value && new Date(value.date) },
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEye} size="1x" />}
                onClick={() => navigate(`/email/${params.row.id}/view`)}
                label="Voir"
            />
        ] }
    ];

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListEmailSend().then(res => {
            setListEmailSend({
                loading: false,
                data: res.data ?? []
            });
        });
    }

    return (
        <div className="list">

            <HeaderList title="Email" icon={faNewspaper}></HeaderList>

            <DataGridPro
                autoHeight
                rows={listEmailSend.data}
                columns={columns}
                pageSize={20}
                className="table"
                loading={listEmailSend.loading}
                pagination
                pageSizeOptions={[20, 50, 100]}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
    
        </div>
    )
}