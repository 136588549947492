import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getListUser = async () => {
    const result = await axios(config.baseUrl + 'api/v1/admin/user', {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data);
}

export const getOneUser = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/user/' + id , {
        headers: {
            'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
        }
    });
    return(result.data.user[0]);
}

export const createUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: `${config.baseUrl}${config.apiBase}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).post('admin/user', data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateUser = async (data, id) => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined,
                'Content-Type': 'application/json',
            }
        }).post(`admin/user/${id}`, data);
    });
}

export const deleteUser = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined
                }
            }).delete('api/v1/admin/user/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

