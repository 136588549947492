	import { useState } from "react";
	import { useForm, useFieldArray } from "react-hook-form";
	import { Button } from "@mui/material";
	import { FormInputText } from "../../Form/FormInputText";
	import { FormButtonSubmit } from "../../Form/FormButtonSubmit";
	import { faPlusMinus, faTrash } from "@fortawesome/free-solid-svg-icons";
	import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
	import { postProjectEmail } from "../../../store/index.service";
	import { FormCKEditor } from "../../Form/FormCKEditor";

	export const SendEmail = ({ handleClosePopup, refreshData, dataStore, lang }) => {
	const {
		handleSubmit,
		control,
		setValue,
		setError,
		...rest
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			emailFieldsOF: {
				email: "sales@superights.net"
			}
		}
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "emailFields",
	});

	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const onSubmit = async (data) => {
		const { emailFields, emailFieldsOF, object, message } = data;

		if (!emailFieldsOF.email) {
			setErrorMsg("L'e-mail de l'expéditeur est requis.");
			setTimeout(() => setErrorMsg(""), 2000);
			return;
		}

		const isValidSenderEmail = emailFieldsOF.email.includes("@superights.");
			if (!isValidSenderEmail) {
			setErrorMsg("L'e-mail de l'expéditeur n'est pas valide ou ne contient pas le domaine 'superights'.");
			setTimeout(() => setErrorMsg(""), 2000);
			return;
		}

		if (emailFields.length === 0) {
			setErrorMsg("Au moins un destinataire d'e-mail est requis.");
			setTimeout(() => setErrorMsg(""), 2000);
			return;
		}

		const newObjects = dataStore.map((item) => ({
			id: item.id,
			comment: item.commentaire || null,
			firstComment: item.firstMessage || null,
			lastComment: item.lastMessage || null,
		}));

		const objectSend = {
			emails: emailFields.map((field) => field.email),
			object,
			envoyeur: emailFieldsOF.email,
			message: message || null,
			items: newObjects,
			lang: lang
		};

		setLoading(true);

		try {
			const response = await postProjectEmail(objectSend);
			if (response.status !== 200) {
				setLoading(false);
				setErrorMsg("Erreur lors de l'envoi de l'e-mail");
				setTimeout(() => setErrorMsg(""), 2000);
				return;
			}
			handleClosePopup();
		} catch (error) {
			setLoading(false);
			setErrorMsg("Erreur lors de l'envoi de l'e-mail");
			setTimeout(() => setErrorMsg(""), 2000);
		}
	};

	const isEmailValid = (email) => {
		const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return regex.test(email);
	};

	const addEmailField = () => {
		append({ email: "" });
	};

	return (
		<div className="popupCreateHorseQuick">
		<h2>Envoyer les vidéos</h2>

		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form">
			<p>De.</p>
			<br />
			<FormInputText
				label="Email de l'expéditeur"
				name="emailFieldsOF.email"
				control={control}
				rules={{
				required: "Ce champ est requis",
				validate: (value) =>
					isEmailValid(value) || "Adresse e-mail invalide",
				}}
			/>

			<br />
			<br />
			<p>Transmettez à.</p>

			{fields.map((field, index) => (
				<div className="row-form" key={field.id}>
				<FormInputText
					label="Email du destinataire"
					name={`emailFields[${index}].email`}
					control={control}
					defaultValue={field.email}
					rules={{
					required: "Ce champ est requis",
					validate: (value) =>
						isEmailValid(value) || "Adresse e-mail invalide",
					}}
				/>

				<FontAwesomeIcon
					style={{
					marginTop: "2%",
					cursor: "pointer",
					color: "red",
					}}
					icon={faTrash}
					size="lg"
					onClick={() => remove(index)}
				/>
				</div>
			))}

			<div className="row-form">
				<Button
				variant="text"
				color="grey"
				size="small"
				className="cancel"
				onClick={addEmailField}
				>
				<FontAwesomeIcon icon={faPlusMinus} size="lg" /> Ajouter un destinataire
				</Button>
			</div>

			<div className="row-form">
				<FormInputText
				name="object"
				label="Object"
				control={control}
				rules={{ required: true }}
				/>
			</div>

			<div className="row-form">
				<FormCKEditor
				name="message"
				label="Message"
				control={control}
				rules={{ required: false }}
				/>
			</div>

			{errorMsg && (
				<p className="error-message" style={{ color: "red" }}>
				{errorMsg}
				</p>
			)}
			</div>

			<div className="row-form" />
			<div className="btn-container">
			<Button
				variant="text"
				color="grey"
				size="small"
				className="cancel"
				onClick={handleClosePopup}
			>
				Annuler
			</Button>

			<FormButtonSubmit loading={loading} children="Envoyer" />
			</div>
		</form>
		</div>
	);
};
